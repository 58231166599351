import * as React from "react"
import "./Partners.scss"
import { getImageUrl } from "../../services/Utils"

const Partners = props => {
  const partners = props.items

  return (
    <div className="container">
      <div className="partners pt-2 pb-6">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h1>{props.title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex flex-wrap justify-content-between align-items-center gap-4">
            {partners && partners.length
              ? partners.map((item, index) => {
                  return (
                    <div key={item.id || index}>
                      <img
                        style={{ maxWidth: "150px" }}
                        src={getImageUrl(item.imageUrl)}
                        alt={item.title}
                      />
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
